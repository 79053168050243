import React, { Component } from 'react';
import bannerImages from './bannerImages.json';
import './Homepage.css';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import MetaTags from 'react-meta-tags';
import { eventService } from '../../services/EventService';
import { announcementsService } from '../../services/AnnouncementsService';
import { ongoingProjectsService } from '../../services/OngoingProjectsService';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';
import { googleVerificationService } from '../../services/GoogleVerificationService';
import Moment from 'react-moment';

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bannerImages: bannerImages,
      lastAnnouncement: {},
      lastEvent: {},
      lastProject: {}
    };
  }

  componentWillMount() {
    eventService
      .lastEvent()
      .then(jsonResponse => {
        if (jsonResponse.data.event.length) {
          this.setState({ lastEvent: jsonResponse.data.event[0] });
        }
      })
      .catch(error => console.log(error));

    announcementsService
      .getLast()
      .then(jsonResponse => {
        if (jsonResponse.data.announcement.length) {
          this.setState({
            lastAnnouncement: jsonResponse.data.announcement[0]
          });
        }
      })
      .catch(error => console.log(error));

    ongoingProjectsService
      .lastProject()
      .then(jsonResponse => {
        if (jsonResponse.data.project.length) {
          this.setState({ lastProject: jsonResponse.data.project[0] });
        }
      })
      .catch(error => console.log(error));
  }

  openUsefullLinks = url => {
    window.open(url, '_blank');
  };

  render() {
    return (
      <div className="homepage">
        <MetaTags>
          <title>Primaria Vicovu de Jos</title>
          <meta
            name="description"
            content="Bine ati venit pe pagina oficiala a primariei Vicovu de Jos."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Jos, Vicovu de Jos, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, Sofia Vicoveanca, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Primaria Vicovu de Jos" />
          <meta
            property="og:url"
            content="https://www.primariavicovudejos.ro/"
          />
        </MetaTags>
        <div className="homepage__banner">
          <ImageNextGen
            class="homepage__banner-img"
            imageUrl="/images/bannerImages/vicovuDeJosHomepage.jpg"
            imageUrlWebp="/images/bannerImages/vicovuDeJosHomepage.webp"
            imageUrlJpeg2000="/images/bannerImages/vicovuDeJosHomepage.jp2"
          />
          {Object.keys(this.state.lastAnnouncement).length ? (
            <div className="homepage__banner-info">
              <div className="homepage__banner-date">
                <h5 className="homepage__date">
                  <Moment
                    format="DD-MM-YYYY"
                    date={this.state.lastAnnouncement.created_at}
                  />
                </h5>
              </div>
              <div className="homepage__banner-description">
                <h3 className="homepage__banner-title">
                  {this.state.lastAnnouncement.title}
                </h3>
                <h4 className="homepage__banner-subtitle">
                  {this.state.lastAnnouncement.description}
                </h4>
              </div>
              <div className="homepage__read-more-box">
                <Link className="homepage__middle-link" to="/anunturi">
                  <h5 className="homepage__read-more">Citeste mai multe</h5>
                </Link>
              </div>
            </div>
          ) : (
            undefined
          )}
        </div>
        <div className='homepage__payment-section'>
         <h3>Plata electronică a impozitelor, taxelor și a amenzilor se poate face accesând următorul link: <a href='https://infopay.ro/i/vicovj' target='_blank'>www.infopay.ro/i/vicovj</a></h3>
        </div>
        <div className="homepage__middle-section">
          <h3 className="homepage__monitor-title">Monitorul Oficial Local</h3>
          <div className="homepage__middle-section-second">
            <div className="homepage__card">
              <Link
                className="homepage__middle-link"
                to="/legislation/statut_2023.pdf"
                target="_blank"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Statutul Unității Administrativ-Teritoriale
                </h4>
              </Link>
            </div>

            <div className="homepage__card">
              <Link
                className="homepage__middle-link"
                to="/regulament-organizare"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Regulamentele privind Procedurile Administrative
                </h4>
              </Link>
            </div>

            <div className="homepage__card">
              <Link className="homepage__middle-link" to="/hotarari-adoptate">
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Hotărârile Autorității Deliberative
                </h4>
              </Link>
            </div>

            <div className="homepage__card">
              <Link
                className="homepage__middle-link"
                to="/dispozitiile-primarului"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Dispozițiile Autorității Executive
                </h4>
              </Link>
            </div>

            <div className="homepage__card">
              <Link
                className="homepage__middle-link"
                to="/informatii-financiare"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Documente și Informații Financiare
                </h4>
              </Link>
            </div>

            <div className="homepage__card">
              <Link
                className="homepage__middle-link"
                to="/documente-interes-public"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Documente de interes public
                </h4>
              </Link>
            </div>
          </div>
        </div>
        <div className="homepage__comunicat">
          <div className="homepage__comunicat-box">
            <h3>
              {' '}
              <b>
                Proiect “Construirea de locuințe de serviciu - NZEB pentru specialiștii din sănătate si invatamant din
                comuna VICOVU DE JOS, judetul SUCEAVA”
              </b>
            </h3>
            <p>
              Comunicat de presă „PNRR: Fonduri pentru România modernă și
              reformată!”
            </p>
            <p> Titlu Apel : PNRR/2022/C10/12, Runda 1</p>
            <p>
              Proiectul se deruleaza pe o perioada de 36 de luni incepand cu data de 07.12.2022.
            </p>
            <p>Obiectivul proiectului il reprezinta construirea de locuinte nZEB pentru specialistii din
              sanatate si invatamant, deasemenea cu scopul si de a atinge o reducere a cererii de energie primară.</p>
            <p>PNRR Componenta 10 — Fondul Local, I.2 – Construirea de locuințe NZEB plus - pentru
              tineri- cod 025b - Construirea de noi clădiri eficiente energetic pentru obiectivul de investitii
              ‘‘Construirea de locuințe de serviciu - NZEB pentru specialiștii din sănătate si invatamant din
              comuna VICOVU DE JOS, judetul SUCEAVA”, in comuna Vicovu de Jos, judetul Suceava.</p>
            <p>Rezultatele urmarite prin implementarea proiectului sunt urmatoarele:</p>
            <ul>
              <li>
                Reducerea cererii de energie primara cu cel putin 20% mai mic decat cerinta pentru cladirile
                al caror consum de energie este aprope egal cu zero conform orientarilor nationale;
              </li>
              <li>
                Mentinerea specialistilor din invatamant si din sanatate in mediul rural, pentru a putea fi
                asigurate cetatenilor servicii de calitate privind sanatatea si invatamantul;
              </li>
              <li> Dezvoltare economica, sociala si culturala a localitatii;</li>
              <li>
                Costuri de intretinere scazute;
              </li>
              <li>Facturi cu consum zero sau cel putin zero;</li>
              <li>Obtinerea unei constante scazute de aer viciat datorita folosirea tehnologiei inteligente;</li>
              <li>Construirea unui modul de patru locuinte - noile unitati de locuit vor fi conforme cu tinta
                privind atingerea pragului de minim 20% consum primar de energie mai mic in comparativ
                cu cerintele privind constructiile NZEB, stipulate in reglementarile nationale. Acest consum
                va fi reflectat in certificatele de performanta energetica.</li>
            </ul>

            <p>
              Valoarea totala a finantarii nerambursabile este de 1.445.403,17, la care se adauga TVA in
              valoare de 274.626,60 lei.
            </p>

            <p>
              {' '}
              „Conținutul acestui material nu reprezintă în mod obligatoriu
              poziția oficială a Uniunii Europene sau a Guvernului României”.
            </p>

            <p>
              Pentru informaţii detaliate, vă invităm să vizitaţi{' '}
              <a
                href="https://mfe.gov.ro/pnrr/"
                target="_blank"
                className="standard__link"
              >
                www.mfe.gov.ro/pnrr/
              </a>
              , pagina de Facebook
              <a
                href="https://www.facebook.com/PNRROficial/"
                target="_blank"
                className="standard__link"
              >
                https://www.facebook.com/PNRROficial/
              </a>
              , precum si{' '}
              <span
                className="standard__link"
                onClick={() => window.open(`/documents/comunicat_proiect_fonduri.pdf`)}
              >
                <b>Comunicatul de presa</b>
              </span>
              .
            </p>
          </div>
        </div>
        <div className="homepage__comunicat">
          <div className="homepage__comunicat-box">
            <h3>
              {' '}
              <b>
                Proiect “Infiintarea a doua
                statii de reincarcare vehicule electrice in comuna VICOVU DE JOS, judetul Suceava”
              </b>
            </h3>
            <p> Titlu Apel : PNRR/2022/C10/I1.3, Runda 1</p>
            <p>
              Proiectul se deruleaza pe o perioada de 36 de luni incepand cu data de 29.11.2022.
            </p>
            <p>Obiectivul proiectului il reprezinta Asigurarea Infrastructurii pentru Transportul Verde -
              achizitionarea de statii de reincarcare vehicule electrice in comuna Vicovu de Jos.</p>
            <p>PNRR – Fondul Local, Componenta I.1.3 - Asigurarea infrastructurii pentru
              transportul verde – puncte de reîncărcare vehicule electrice reprezintă oportunitatea imediată și
              sigură de a realiza obiectivele propuse.</p>
            <p>Obiectivul proiectului consta in cresterea capacitatilor de producere a energiei electrice din
              surse regenerabile cu o putere instalata de 50 kWp pentru asigurarea consumului propriu si
              dezvoltarea electro-mobilitatii reale prin realizarea a doua statii de reincarcare pentru vehicule
              electrice si electrice hybrid plug-in.</p>
            <p>Rezultatele urmarite prin implementarea proiectului sunt urmatoarele:</p>
            <ul>
              <li>
              Infiintarea a doua statii de incarcare pentru vehicule electrice;
              </li>
              <li>
              Dezvoltarea infrastructurii de alimentare a vehiculelor cu energie electrica;
              </li>
              <li>Imbunatatirea calitatii mediului;</li>
              <li>
              Dezvoltarea transportului ecologic;
              </li>
              <li>Cresterea numarului de automobile electrice;</li>
              <li>Cresterea numarului de utilizatori de automobile electrice si hybrid in urmatorii ani si
de a dezvolta infrastructura necesara alimentarii acestor automobile;</li>
              <li>Constientizarea beneficiilor utilizarii autovehiculelor prietenoase cu mediul
inconjurator.</li>
            </ul>

            <p>
            Valoarea totala a finantarii nerambursabile este de 246.135,00 lei, la care se adauga TVA in
valoare de 46.765,65 lei.
            </p>

            <p>
              {' '}
              „Conținutul acestui material nu reprezintă în mod obligatoriu
              poziția oficială a Uniunii Europene sau a Guvernului României”.
            </p>

            <p>
              Pentru informaţii detaliate, vă invităm să vizitaţi{' '}
              <a
                href="https://mfe.gov.ro/pnrr/"
                target="_blank"
                className="standard__link"
              >
                www.mfe.gov.ro/pnrr/
              </a>
              , pagina de Facebook
              <a
                href="https://www.facebook.com/PNRROficial/"
                target="_blank"
                className="standard__link"
              >
                https://www.facebook.com/PNRROficial/
              </a>
              , precum si{' '}
              <span
                className="standard__link"
                onClick={() => window.open(`/documents/comunicat_proiect_statii_electrice.pdf`)}
              >
                <b>Comunicatul de presa</b>
              </span>
              .
            </p>
          </div>
        </div>
        {
          // <div className="homepage__taxes-section">
          //   <h3 className="homepage__taxes">
          //     {' '}
          //     Pentru plata impozitelor, taxelor locale și amenzilor, Primăria vă
          //     pune la dispoziție{' '}
          //     <a
          //       className="homepage__link standard__taxes-link"
          //       href="https://infopay.ro/Institutie/vicovs"
          //       target="_blank"
          //       rel="noopener noreferrer"
          //     >
          //       {' '}
          //       portalul online InfoPay
          //     </a>
          //     . Acest sistem de plată electronică este disponibil atât pentru
          //     persoanele fizice, cât și pentru persoanele juridice.
          //   </h3>
          //   <h3 className="homepage__taxes">
          //     {' '}
          //     Pentru plata impozitelor și taxelor locale prin intermediul InfoPay
          //     nu se percep comisioane pentru tranzacțiile efectuate.
          //   </h3>
          // </div>
        }
        <div className="homepage__events-and-projects">
          <div className="homepage__general-news">
            <h2 className="homepage__general-title">Evenimente</h2>
            {Object.keys(this.state.lastEvent).length ? (
              <div>
                <h3 className="homepage__general-subtitle">
                  Cel mai recent eveniment din localitatea noastră
                </h3>
                <div className="homepage__general-pic-box">
                  <img
                    src={
                      this.state.lastEvent.picture &&
                        this.state.lastEvent.picture.url
                        ? `${process.env.REACT_APP_API_URL}/${this.state.lastEvent.picture.url
                        }`
                        : ''
                    }
                    className="homepage__general-news-pic"
                    alt="event-pic"
                  />
                  <div className="homepage__news-date-box">
                    <h4 className="homepage__news-date">
                      <Moment
                        format="DD-MM-YYYY"
                        date={this.state.lastEvent.start_date}
                      />
                    </h4>
                  </div>
                </div>
                <h3 className="homepage__general-name">
                  {this.state.lastEvent.title}
                </h3>
                <h4 className="homepage__general-description">
                  {this.state.lastEvent.description}
                </h4>
                <button className="homepage__general-button">
                  <h5 className="homepage__general-see-more">Vezi eveniment</h5>
                </button>
              </div>
            ) : (
              undefined
            )}
          </div>
          <div className="homepage__general-news homepage__project">
            <h2 className="homepage__general-title homepage__project-main-title">
              Proiecte
            </h2>
            <h3 className="homepage__general-subtitle homepage__project-subtitle">
              Cel mai recent proiect din localitatea noastră
            </h3>
            {Object.keys(this.state.lastProject).length ? (
              <div>
                <div className="homepage__general-pic-box">
                  <img
                    src={
                      this.state.lastProject.picture &&
                        this.state.lastProject.picture.url
                        ? `${process.env.REACT_APP_API_URL}/${this.state.lastProject.picture.url
                        }`
                        : ''
                    }
                    className="homepage__general-news-pic"
                    alt="project-pic"
                  />
                  <div className="homepage__news-date-box homepage__project-date-box">
                    <h4 className="homepage__news-date homepage__project-date">
                      <Moment
                        format="DD-MM-YYYY"
                        date={this.state.lastProject.start_date}
                      />
                    </h4>
                  </div>
                </div>
                <h3 className="homepage__general-name homepage__project-name">
                  {this.state.lastProject.title}
                </h3>
                <h4 className="homepage__general-description homepage__project-description">
                  {this.state.lastProject.description}
                </h4>
                <button className="homepage__general-button">
                  <h5 className="homepage__general-see-more">Vezi proiect</h5>
                </button>
              </div>
            ) : (
              undefined
            )}
          </div>
        </div>
        <div className="homepage__usefull-links">
          <h2>Linkuri Externe</h2>
          <div className="homepage__usefull-links-list">
            <div className="homepage__card homepage__card-link">
              <Link
                to="https://www.recensamantromania.ro/cum-devin-recenzor/"
                target="_blank"
              >
                <img
                  src="/images/icons/unlink.svg"
                  className="homepage__link-icon"
                  alt="link-icon"
                />
                <h3 className="homepage__link-title">
                  Cum devin recenzor?
                </h3>
              </Link>
            </div>
            <div className="homepage__card homepage__card-link">
              <Link
                to="https://sgg.gov.ro/new/guvernare-transparenta-deschisa-si-participativa-standardizare-armonizare-dialog-imbunatatit-cod-sipoca-35/"
                target="_blank"
              >
                <img
                  src="/images/icons/unlink.svg"
                  className="homepage__link-icon"
                  alt="link-icon"
                />
                <h3 className="homepage__link-title">
                  Guvernare transparentă, deschisă și participativă
                </h3>
              </Link>
            </div>
            <div className="homepage__card homepage__card-link">
              <Link to="/documents/CoronaVirusPrevenire.pdf">
                <img
                  src="/images/icons/unlink.svg"
                  className="homepage__link-icon"
                  alt="link-icon"
                />
                <h3 className="homepage__link-title">
                  Masuri de protectie impotriva CoronaVirus
                </h3>
              </Link>
            </div>
          </div>
        </div>
        <CookieConsent
          location="bottom"
          flipButtons
          buttonText="Accept Cookies"
          declineButtonText="Refuz Cookies"
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
        >
          Acest site folosește cookies. Navigând în continuare vă exprimați
          acordul asupra folosirii cookie-urilor.{' '}
        </CookieConsent>
      </div>
    );
  }
}

export default Homepage;
